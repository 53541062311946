exports.components = {
  "component---src-pages-about-about-css-ts": () => import("./../../../src/pages/about/about.css.ts" /* webpackChunkName: "component---src-pages-about-about-css-ts" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-footer-footer-css-ts": () => import("./../../../src/pages/footer/footer.css.ts" /* webpackChunkName: "component---src-pages-footer-footer-css-ts" */),
  "component---src-pages-footer-index-tsx": () => import("./../../../src/pages/footer/index.tsx" /* webpackChunkName: "component---src-pages-footer-index-tsx" */),
  "component---src-pages-home-home-css-ts": () => import("./../../../src/pages/home/home.css.ts" /* webpackChunkName: "component---src-pages-home-home-css-ts" */),
  "component---src-pages-home-index-tsx": () => import("./../../../src/pages/home/index.tsx" /* webpackChunkName: "component---src-pages-home-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-portfolio-git-hub-item-tsx": () => import("./../../../src/pages/portfolio/GitHubItem.tsx" /* webpackChunkName: "component---src-pages-portfolio-git-hub-item-tsx" */),
  "component---src-pages-portfolio-github-item-css-ts": () => import("./../../../src/pages/portfolio/githubItem.css.ts" /* webpackChunkName: "component---src-pages-portfolio-github-item-css-ts" */),
  "component---src-pages-portfolio-index-tsx": () => import("./../../../src/pages/portfolio/index.tsx" /* webpackChunkName: "component---src-pages-portfolio-index-tsx" */),
  "component---src-pages-portfolio-portfolio-css-ts": () => import("./../../../src/pages/portfolio/portfolio.css.ts" /* webpackChunkName: "component---src-pages-portfolio-portfolio-css-ts" */),
  "component---src-pages-theme-ts": () => import("./../../../src/pages/theme.ts" /* webpackChunkName: "component---src-pages-theme-ts" */)
}

